.header__background {
    background-image: url(../../assets/bg-header.jpg);
    background-position: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header__contact {
    display: flex;
    text-align: center;
    justify-content: center;
}

.header__background-title h1 {
    color: var(--white);
}

.header__contact svg {
    margin: 0px 20px;
}

.header__hr {
    border: 3px solid var(--white);
    width: 50%;
    text-align: center;
    border-radius: 10px;
}

.header__contact-icon a svg {
    transition: all 0.5s;
}

.header__contact-icon a svg:hover {
    transition: all 0.5s;
    transform: scale(1.2);
}

@media (max-width: 575px) {
    .header__background-title h1 {
        font-size: 25px;
    }
}

@media (max-width: 767px) and (min-width: 576px) {
    .header__background-title h1 {
        font-size: 50px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .header__background-title h1 {
        font-size: 55px;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .header__background-title h1 {
        font-size: 80px;
    }
}

@media (min-width: 1200px) {
    .header__background-title h1 {
        font-size: 100px;
    }
}