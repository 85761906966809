/* .skills__description {
    padding: 0px 50px;
} */

.skills__subtitle-background {
    /* -webkit-text-fill-color: transparent; */
    -webkit-text-stroke-width: 2px;
    color: var(--blue);
    display: inline-block;
    font-size: 100px;
    font-weight: 700;
    opacity: .06;
    position: absolute;
    z-index: 0;
}

@media (max-width: 575px) {
    .skills__subtitle-background {
        font-size: 60px;
    }
}

@media (max-width: 767px) and (min-width: 576px) {
    .skills__subtitle-background {
        font-size: 75px;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .skills__subtitle-background {
        font-size: 70px;
        text-align: center;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {
    .skills__subtitle-background {
        text-align: center;
    }
}

@media (min-width: 1200px) {}