body {
  margin: 0;
  padding: 0;
  font-family: 'Spartan', sans-serif;
  background-color: var(--white);
  color: var(--black);
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: var(--blue);
  transition: all 0.3s;
}

a p {
  transition: all 0.3s;
}

a p:hover {
  transition: all 0.3s;
  letter-spacing: 5px;
}

p {
  letter-spacing: 2.5px;
  font-size: 18px;
  text-align: justify;
}

h1 {
  letter-spacing: 2.5px;
}

h2 {
  letter-spacing: 2.5px;
}

h3 {
  letter-spacing: 2.5px;
}

h4 {
  letter-spacing: 2.5px;
}

h5 {
  letter-spacing: 2.5px;
}

.white-color {
  color: var(--white);
}

.beige-color {
  color: var(--beige);
}

.black-color {
  color: var(--black);
}

.dark-gray-color {
  color: var(--dark-gray);
}

.yellow-color {
  color: var(--yellow);
}

.blue-color {
  color: var(--blue);
}

.glass__card {
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4.5px);
  background: hsla(0,0%,100%,.25);
  border: 1px solid hsla(0,0%,100%,.18);
  border-radius: 10px;
  box-shadow: 0 8px 32px 0 rgba(31,38,135,.37);
  padding: 35px 5px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

:root {
  --white: #f8f8f8;
  --beige: #DDD0C8;
  --black: #1D1D1D;
  --blue: #178582;
  --dark-gray: #323232;
  --gray: #CCCCCC;
  --true-white: #FFFFFF;
  --yellow: #FFCC00;
}