.projects {
    background-color: var(--true-white);
}

.projects__description {
    padding-right: 60px;
}

.projects__techIcons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 20px;
}

.projects__techIcons .tooltop svg {
    font-size: 40px;
}

.tooltop {
    position: relative;
    display: inline-block;
    cursor: pointer;
    /* overflow: hidden; */
}
  
.tooltop .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: var(--gray);
    /* color: var(--white); */
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
}

.tooltop:hover .tooltiptext {
    visibility: visible;
}

.projects__technologies {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.owl-item img {
    border-radius: 8px;
}

@media (max-width: 575px) {
    .projects__description {
        padding: 0px 10px;
    }

    .tooltop {
        overflow: hidden;
    }
}

@media (max-width: 767px) and (min-width: 576px) {
    .projects__description {
        padding: 0px 10px;
    }

    .tooltop {
        overflow: hidden;
    }
}

@media (max-width: 991px) and (min-width: 768px) {
    .projects__description {
        padding-right: 30px;
    }

    .tooltop {
        overflow: hidden;
    }
}

@media (max-width: 1199px) and (min-width: 992px) {}

@media (min-width: 1200px) {}